import React from "react";
import { Link } from "react-router-dom";
import { Fardiga, Button } from "./../../../../reusable/index"
import FastOne from "./../../../../assets/images/fastigheter/fast-one.png"
import FastTwo from "./../../../../assets/images/fastigheter/fast-two.png"
import FastThree from "./../../../../assets/images/fastigheter/fast-three.png"
const Fade = require("react-reveal/Fade")

const Projekt = () => {
	return (
		<div className="w-full flex justify-center pt-[5%] pb-[5%]">
            <div className="w-[90%]">
				<h1 className="text-black text-4xl font-bold uppercase tracking-wider pb-20 text-center">Pågående projekt</h1>
				<div className="w-full grid grid-cols-3 grid-rows-1 gap-10 xl:grid-cols-2 md:grid-cols-1">
					<Fardiga image={FastOne} location="STRÖMSTAD" type="HYRESRÄTTER" title="MYREN" infoOne="Färdigställt" infoTwo="2023"></Fardiga>
					<Fardiga image={FastTwo} location="TANUM" type="HYRESRÄTTER" title="OPPEN" infoOne="Färdigställt" infoTwo="2024"></Fardiga>
					<Fardiga image={FastThree} location="KARLSTAD" type="BOSTADSRÄTTER" title="GOLFKLUBB" infoOne="Färdigställt" infoTwo="2023"></Fardiga>
				</div>
				<div className="flex justify-center pt-16">
					<Button link="/pagaende-projekt" text="visa alla"></Button>
				</div>
			</div>
		</div>
	);
};

export default Projekt;