import React from "react";
import {Helmet} from "react-helmet";
import { Banner, Info, Carousel, InfoImage, Maps } from "./components/index";
import Image from "./../../assets/images/fastigheter/kyrkasen.jpg";

const Kyrkasen = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Kyrkåsen, Sandviken</title>
                <link rel="canonical" href="https://hofbro.se/" />
            </Helmet>
            <Banner 
                image={Image} 
                location="Pågående" 
                title="Kyrkåsen, Sandviken" 
                text="Hofbro fastigheter tillsammans med Idun utvecklar ett bostadsprojekt i Sandviken med fokus på
                kvadratsmarta lägenheter." 
                Objekt="Hyresrätter"
                Omfattning="110 Lägenheter"
                Byggherre="IDUN Fastigheter"
                Arkitekt="Södergruppen"
                Entreprenor=""
                ></Banner>

        </main>
	);
};

export default Kyrkasen;
