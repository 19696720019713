import React from "react";
import Item from "./item"
import IconOne from "./../../../../assets/icons/icon-one.png";
import IconTwo from "./../../../../assets/icons/icon-two.png";
import IconThree from "./../../../../assets/icons/icon-three.png";
import IconFour from "./../../../../assets/icons/icon-four.png";
import IconFive from "./../../../../assets/icons/icon-five.png";
import IconSix from "./../../../../assets/icons/icon-six.png";
const Fade = require("react-reveal/Fade")

const Utveckling = () => {
	return (
		<div className="w-full flex justify-center bg-[#111736] pt-[5%] pb-[5%]">
            <div className="w-[75%] flex flex-col items-center xl:w-[90%]">
				<h1 className="text-white text-4xl font-bold uppercase tracking-wider pb-20 md:text-center">Fastighets<br className="hidden md:flex"></br>utveckling</h1>
				<div className="w-full grid grid-cols-3 gap-10 xl:grid-cols-2 md:grid-cols-1">
					<Item icon={IconOne} title="Idé" text="Idéer föds ofta genom god kunskap om marknaden och i samverkan med starka nätverk."></Item>
					<Item icon={IconTwo} title="Utveckling" text="Utveckling av idén sker i nära samverkan med marknadens olika aktörer."></Item>
					<Item icon={IconThree} title="Ägarstruktur" text="Hofbro Fastigheter har genom sina goda relationer till investerare möjlighet att anskaffa kapital till projekten."></Item>

					<Item icon={IconFour} title="Finansiering" text="Hofbro Fastigheter har genom kunskap, erfarenhet och goda relationer med banker och andra finansieringsinstitut möjlighet till flera"></Item>
					<Item icon={IconFive} title="Genomförande" text="Sker i nära samverkan med entreprenörer där vi tillsammans finner optimala lösningar för projekten."></Item>
					<Item icon={IconSix} title="Förvaltning" text="Hofbro Fastigheter har genom partners möjlighet att erbjuda både försäljning och förvaltning av våra projekt."></Item>
				</div>
			</div>
		</div>
	);
};

export default Utveckling;