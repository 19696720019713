import React from "react";
import {Helmet} from "react-helmet";
import { Banner, Info, Carousel, InfoImage, Maps } from "./components/index";
import Image from "./../../assets/images/fastigheter/sannakajen/sannakajen.jpg";
import Image1 from "./../../assets/images/fastigheter/sannakajen/sannakajen2.jpg";
import Image2 from "./../../assets/images/fastigheter/sannakajen/sannakajen3.jpg";


const Sannakajen = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Kristinehamn, Sannakajen</title>
                <link rel="canonical" href="https://hofbro.se/" />
            </Helmet>
            <Banner 
                image={Image} 
                location="Pågående" 
                title="Kristinehamn, Sannakajen" 
                text="Sannakajen är ett nytt bostadskvarter som ligger vackert vid Vänerns inlopp samtidigt som det är
                promenadavstånd till centrum. Här ska Hofbro fastigheter, tillsammans med Strömvold, utveckla
                126 yteffektiva hyreslägenheter." 
                Objekt="Hyresrätter"
                Omfattning="126 Lägenheter"
                Byggherre="Strömvold"
                Arkitekt="Tengbom"
                Entreprenor="NCC"
                ></Banner>

<Carousel image1={Image1}  image2={Image2} image3="" image4="" image5="" image6="" />


        </main>
	);
};

export default Sannakajen;
