import React from "react";
import {Helmet} from "react-helmet";
import { Banner, Info, Carousel, InfoImage, Maps } from "./components/index";
import Image from "./../../assets/images/fastigheter/oppen.tanum.jpg";

const Oppentanum = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Oppen, Tanum</title>
                <link rel="canonical" href="https://hofbro.se/" />
            </Helmet>
            <Banner 
                image={Image} 
                location="Pågående" 
                title="Oppen, Tanum" 
                text="Oppen ska bli ett nytt kvarter av bostäder som ligger ett stenkast från Tanum shoppingcentret.
                Husen på Oppen är placerade så att små mysiga innergårdar skapas och bidrar till spontana möten
                mellan grannarna. Hofbro utvecklar projektet tillsammans med Strömvold." 
                Objekt="Hyresrätter"
                Omfattning="62 Lägenheter"
                Byggherre="Strömvold"
                Arkitekt="Werner Arkitekter"
                Entreprenor=""
                ></Banner>

        </main>
	);
};

export default Oppentanum;
