interface BannerProps {
	image: string;
	title: string;
	text: string;
}

const Banner = (props: BannerProps) => {
	return (
		<div className="w-full h-[55vh] flex justify-center items-center bg-cover bg-center bg-no-repeat" style={{backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .65), rgba(0,0,0, .65)), url(${props.image})`}}>
			<div className="text-center">
				<h1 className="text-6xl uppercase font-black tracking-widest xl:text-4xl md:text-3xl">{props.title}</h1>
				<p className="text-xl opacity-80 xl:text-base md:text-sm">{props.text}</p>
			</div>
		</div>
	);
};

export default Banner;