import { Link } from "react-router-dom";
import "./../../index.css";

interface ItemProps {
	image: string;
	location: string;
	type: string;
	title: string;
	infoOne: string;
	infoTwo: string;
	link?: string;
    href?: string;
	pagaende?: boolean;
	fardiga?: boolean;
}

const Fardiga = (props: ItemProps) => {
	return (
		<div className="flex flex-col w-full h-[30rem] bg-white rounded-lg shadow-sm">
			<div className="h-[50%] w-full bg-cover bg-center bg-no-repeat rounded-t-lg" style={{backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .2), rgba(0,0,0, .2)), url(${props.image})`}}></div>
			<div className="h-[1px] relative flex justify-center items-center">
				<div className="bg-[#111736] absolute pl-6 pr-6 pt-2 pb-2 rounded-lg">
					<h1 className="text-white text-sm uppercase font-bold tracking-wider">{props.location}</h1>
				</div>
			</div>
			<div className="h-[50%] flex flex-col items-center justify-center gap-2">
				<h3 className="text-black font-bold tracking-wider">{props.type}</h3>
				<h1 className="text-black text-3xl uppercase font-extrabold tracking-wider text-center">{props.title}</h1>
				<div className={`hidden gap-2 ${
					props.pagaende ? "!flex" : ""
				}`}>
					<a href={props.href} className="text-black pt-3">Till projektet</a>
				</div>
				<div className={`hidden gap-2 ${
					props.fardiga ? "!flex justify-center" : ""
				}`}>
					<h3 className="text-black">{props.infoTwo}</h3>
				</div>
				<div className="w-full flex justify-center mt-2 hidden">
					<Link to="" className="btn2 px-5 py-2 relative border-2 border-[#111736] text-[#111736] uppercase font-semibold tracking-wider leading-none overflow-hidden rounded-lg hover:text-white">
						<span className="absolute inset-0 bg-[#111736]"></span>
						<span className="absolute inset-0 flex justify-center items-center font-bold"> 
						LÄS MER
						</span>
						LÄS MER
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Fardiga;