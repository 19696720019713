import React from "react";
import GoogleMapReact from "google-map-react";
import { Location as OfficeLocation } from "./locations/offices";
import { Location as PropertyLocation } from "./locations/property";
import OfficeMarker from "./OfficeMarker";
import PropertyMarker from "./PropertyMarker";

const defaultProps = {
	center: {
		lat: 59.33516597542998,
		lng: 18.083040000288346,
	},
	zoom: 7,
	hoverDistance: 28,
};

const GoogleMap = (props: { locations: OfficeLocation[] | PropertyLocation[]; isProperty?: boolean }) => {
	return (
		// Important! Always set the container height explicitly
		<div style={{ height: "100%", width: "100%" }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: "AIzaSyDXIUQ23huW95UZVn36wBNmNxfhewSQKvI" }}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}
				hoverDistance={defaultProps.hoverDistance}
			>
				{/* Fattar inte varför det behöver vara såhör, kan inte använda en fragment verkar de som */}
				{props.isProperty
					? props.locations.map((location) => (
							<PropertyMarker
								lat={location.pos[0]}
								lng={location.pos[1]}
								{...(location as PropertyLocation)}
							/>
					  ))
					: props.locations.map((location) => (
							<OfficeMarker
								lat={location.pos[0]}
								lng={location.pos[1]}
								{...(location as OfficeLocation)}
							/>
					  ))}
			</GoogleMapReact>
		</div>
	);
};

export default GoogleMap;
{
	/* https://github.com/google-map-react/google-map-react */
}
