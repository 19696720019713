import React from "react";
import { Link, useNavigate } from "react-router-dom";

const MenuOverlay = ({ navbarOpen, setNavbarOpen }) => {
	let navigate = useNavigate();

	const handleNavigate = (e, destination) => {
		navigate(destination);
		e.preventDefault();
		setNavbarOpen(false);

		document.body.style.overflowY = "initial";
	};

	return (
		<nav
			id="megaMenu"
			className={`fixed flex justify-center items-center top-0 right-0 w-[100%] md:pl-12 md:pr-0 md:w-[100%] xl:pl-20 z-40 h-screen pt-24 pb-24 pl-52 pr-52 bg-zinc-900 text-white bg-opacity-100 transform delay-200 transition-all duration-1000 ${
				navbarOpen ? "opacity-100 translate-x-0" : "opacity-0 translate-x-full"
			}`}
		>
			<div className="flex w-full gap-10">
				<ul className="flex flex-col items-start justify-end">
					<div className="h-[3rem]">
						<h1 className="text-xl font-semibold tracking-widest text-white uppercase opacity-40 md:text-lg">
							Navigering
						</h1>
					</div>

					<li className="nav-li mb-3">
						<a
							className="text-4xl text-white uppercase font-bold tracking-wider hover:opacity-70 duration-300 ease-in-out cursor-pointer md:text-2xl"
							onClick={(e) => {
								handleNavigate(e, "/");
							}}
						>
							Hem
						</a>
					</li>
					<li className="nav-li mb-3">
						<a
							className="text-4xl text-white uppercase font-bold tracking-wider hover:opacity-70 duration-300 ease-in-out cursor-pointer md:text-2xl"
							onClick={(e) => {
								handleNavigate(e, "/kontakta-oss");
							}}
						>
							Om oss
						</a>
					</li>
					<li className="nav-li mb-3">
						<a
							className="text-4xl text-white uppercase font-bold tracking-wider hover:opacity-70 duration-300 ease-in-out cursor-pointer md:text-2xl"
							onClick={(e) => {
								handleNavigate(e, "/pagaende-projekt");
							}}
						>
							Pågående projekt
						</a>
					</li>
					<li className="nav-li mb-3">
						<a
							className="text-4xl text-white uppercase font-bold tracking-wider hover:opacity-70 duration-300 ease-in-out cursor-pointer md:text-2xl"
							onClick={(e) => {
								handleNavigate(e, "/fardiga-projekt");
							}}
						>
							Avslutade projekt
						</a>
					</li>
					<li className="nav-li mb-3">
						<a
							className="text-4xl text-white uppercase font-bold tracking-wider hover:opacity-70 duration-300 ease-in-out cursor-pointer md:text-2xl"
							onClick={(e) => {
								handleNavigate(e, "/kontakta-oss");
							}}
						>
							Kontakta oss
						</a>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default MenuOverlay;
