import React from "react";
import logo from "./../../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

const Header = ({ navbarOpen, setNavbarOpen }) => {
	const handleTogglePress = () => {
		setNavbarOpen(!navbarOpen);

		document.body.style.overflowY = navbarOpen ? "initial" : "hidden";
	};

	return (
		<header className="w-full absolute z-50 h-[10vh]">
			<div className="pl-[5%] pr-[5%] flex justify-between h-full items-center">
				<Link to="/">
					<img src={logo} className="w-24"></img>
				</Link>

				<button
					id="toggleScroll"
					className="justify-center p-0 m-0 z-20 relativew-12 hidden lg:flex"
					onClick={handleTogglePress}
				>
					<div className="absolute transform w-5 -translate-x-1/2 -translate-y-1/2 top-1/2">
						<span
							className={`btn-span absolute ease-in-out duration-500 h-0.5 w-5 bg-white transform transition ${
								navbarOpen ? "rotate-45 delay-200" : "-translate-y-1.5"
							}`}
						></span>
						<span
							className={`btn-span absolute ease-in-out duration-400 h-0.5 bg-white transform transition-all ${
								navbarOpen ? "w-0 opacity-50" : "w-5 delay-200 opacity-100"
							}`}
						></span>
						<span
							className={`btn-span absolute ease-in-out duration-500 h-0.5 w-5 bg-white transform transition ${
								navbarOpen ? "-rotate-45 delay-200" : "translate-y-1.5"
							}`}
						></span>
					</div>
				</button>

				<div className="flex gap-5 lg:hidden" id="nav-shift">
					<Link className="shift-link" to="/">
						<span className="text-white uppercase tracking-wider font-semibold">Hem</span>
					</Link>
					<Link className="shift-link" to="/kontakta-oss">
						<span className="text-white uppercase tracking-wider font-semibold">Om oss</span>
					</Link>
					<Link className="shift-link" to="/pagaende-projekt">
						<span className="text-white uppercase tracking-wider font-semibold">Pågående projekt</span>
					</Link>
					<Link className="shift-link" to="/fardiga-projekt">
						<span className="text-white uppercase tracking-wider font-semibold">Avslutade projekt</span>
					</Link>
					<Link className="shift-link" to="/kontakta-oss">
						<span className="text-white uppercase tracking-wider font-semibold">Kontakta oss</span>
					</Link>
				</div>
			</div>
		</header>
	);
};

export default Header;
