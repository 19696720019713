import React from "react";
import { GoogleMap } from "../../../../reusable/index";
import Locations from "../../../../reusable/google-map/locations/ongoing";
import Hakan from "./../../../../assets/images/employee/hakan_sv.jpg";
import Oscar from "./../../../../assets/images/employee/oscar_sv.jpg";

const Fade = require("react-reveal/Fade");

const Maps = () => {
	return (
		<div className="w-full flex justify-center items-center pt-[2.5%] pb-[5%] 2md:pt-[5%] 2md:pb-[10%] 2md:hidden">
			<div className="w-full h-[65vh] gap-10 overflow-hidden rounded-lg 2xl:w-full lg:flex-col">
				<GoogleMap locations={Locations} isProperty></GoogleMap>
			</div>
		</div>
	);
};

export default Maps;