import React from "react";
import Item from "./item";
import { GoogleMap } from "../../../../reusable/index";
import locations from "../../../../reusable/google-map/locations/offices";
import Hakan from "./../../../../assets/images/employee/hakan_sv.jpg";
import Oscar from "./../../../../assets/images/employee/oscar_sv.jpg";

const Fade = require("react-reveal/Fade");

const Employee = () => {
	return (
		<div className="w-full flex justify-center items-center pt-[2.5%] pb-[5%] 2md:pt-[5%] 2md:pb-[10%] 2md:hidden">
			<div className="w-[85%] h-[65vh] gap-10 overflow-hidden rounded-lg 2xl:w-[90%] lg:flex-col">
				<GoogleMap locations={locations}></GoogleMap>
			</div>
		</div>
	);
};

export default Employee;
