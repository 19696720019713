import React from "react";
import {Button} from "./../../../../reusable/index";
const Fade = require("react-reveal/Fade")

interface ImageProps {
	image: string;
}

const Historia = (props: ImageProps) => {
	return (
		<div className="w-full flex justify-center pb-[5%] pt-[5%] lg:pt-[10%]">
            <div className="w-[70%] flex justify-between items-center gap-20 lg:w-[90%]">
				<div className="w-2/4 2md:w-full">
					<h1 className="text-[#111736] text-4xl font-bold uppercase tracking-wider pb-5">Historia</h1>
					<p className="text-[#111736] text-base">Vi vill vara en god och kompetent partner inom fastighetsutveckling av både bostads och kommersiella projekt. Uppdragen kan utföras både i egen regi och i delägarskap.
					<br></br><br></br>
					Hofbro Fastigheter AB har sitt ursprung i Prepart AB och har under åren utvecklat många fina bostads och kommersiella projekt både genom nyproduktion och ombyggnation. Vi är verksamma i flera olika regioner så som Karlstad, Stockholm, Uppsala, Sundsvall, Bohuslän med fler.
					<br></br><br></br>
					Med stor erfarenhet, kunskap och i nära samverkan med våra samarbetspartners uppnår
					vi goda resultat.</p>
					<div className="flex justify-start pt-8">
						<Button link="/kontakta-oss" text="Läs mer"></Button>
					</div>
				</div>
				<div className="w-2/4 h-[28rem] 2md:hidden">
					<div className="bg-center bg-cover bg-no-repeat w-full h-full rounded-lg" style={{backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .25), rgba(0,0,0, .25)), url(${props.image})`}}></div>
				</div>
			</div>
		</div>
	);
};

export default Historia;