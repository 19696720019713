import React from "react";
import { Helmet } from "react-helmet";
import {Banner} from "../../reusable/index";
import { Body } from "./components/index"
import BG from "./../../assets/images/contemporary-design-of-multifamily-living-houses-modern-luxury-apartments-buildings-.jpg"

const Fardiga = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Färdiga projekt</title>
                <link rel="canonical" href="https://hofbro.se/fardiga-projekt" />
            </Helmet>
            <Banner image={BG} title="Avslutade projekt" text="" />
            <Body />
		</main>
	);
};

export default Fardiga;