import React from "react";
import {Helmet} from "react-helmet";
import { Banner, Info, Carousel, InfoImage, Maps } from "./components/index";
import Image from "./../../assets/images/fastigheter/fullero/fullero.jpg";
import Image1 from "./../../assets/images/fastigheter/fullero/fullero1.jpg";
import Image2 from "./../../assets/images/fastigheter/fullero/fullero2.jpg";


const Fullero = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Uppsala, Fullerö</title>
                <link rel="canonical" href="https://hofbro.se/" />
            </Helmet>
            <Banner 
                image={Image} 
                location="Pågående" 
                title="Uppsala, Fullerö" 
                text="Fullerö är en stadsdel som har växt fram de senaste fem åren och ligger mellan Uppsala och
                Storvreta. Här utvecklar Hofbro tillsammans med Idun förskola, skola och flerbostadshus.
                Målsättning är att inflytt ska ske under 2025." 
                Objekt="Hyresrätter, Skola &amp; Förskola"
                Omfattning="100 lägenheter, f-6 Skola, 8 avdelningar förskola"
                Byggherre="IDUN Fastigheter"
                Arkitekt="Metod"
                Entreprenor=""
                ></Banner>

<Carousel image1={Image1}  image2={Image2} image3="" image4="" image5="" image6="" />


        </main>
	);
};

export default Fullero;
