import React from "react";
import {Helmet} from "react-helmet";
import { Hero, Historia, Utveckling, Projekt, Fardiga } from "./components/index"
import Image from "./../../assets/images/pexels-hassan-ouajbir-1535244.jpg";

const Hem = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Hem</title>
                <link rel="canonical" href="https://hofbro.se/" />
            </Helmet>
            <Hero></Hero>
            <Historia image={Image}></Historia>
            <Utveckling></Utveckling>
            <Projekt></Projekt>
            <Fardiga></Fardiga>
		</main>
	);
};

export default Hem;
