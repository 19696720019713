import React from "react";
import {Helmet} from "react-helmet";
import { Banner, Info, Carousel, InfoImage, Maps } from "./components/index";
import Image from "./../../assets/images/fastigheter/barken/barken.jpg";
import Image1 from "./../../assets/images/fastigheter/barken/barken1.jpg";
import Image2 from "./../../assets/images/fastigheter/barken/barken2.jpg";

const Barken = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - BRF Bröderna Persson, Karlstad</title>
                <link rel="canonical" href="https://hofbro.se/" />
            </Helmet>
            <Banner 
                image={Image} 
                location="Pågående" 
                title="BRF Bröderna Persson, Karlstad" 
                text="Brf Br Persson är sista pusselbiten som färdigställer Bryggudden. Hofbro fastigheter utvecklar projektet tillsammans med IDUN fastigheter. Projektet ligger längs pråmkanalen och kommer bestå av 22 st bostadsrättslägenheter. Projektets arkitektur kommer ha samma omsorgsfulla uttryck som sina systerprojekt längs kanalen men med en industriell hållning för minnet av Bröderna Perssons verkstad." 
                Objekt="Bostadsrätter"
                Omfattning="22 Lägenheter"
                Byggherre="IDUN Fastigheter"
                Arkitekt="Tengbom"
                Entreprenor="NCC"
                ></Banner>

<Carousel image1={Image1}  image2={Image2} image3="" image4="" image5="" image6="" />


        </main>
	);
};

export default Barken;
