import React from "react";
import Location from "./../../../../assets/icons/location.png";
import Email from "./../../../../assets/icons/email.png";
import Phone from "./../../../../assets/icons/telephone.png";
const Fade = require("react-reveal/Fade")

interface ItemProps {
    avatar: string;
    name: string;
    title: string;
    phone: string;
    email: string;
    adress: string;
    city: string;
    cords: string;
}

const Item = (props: ItemProps) => {
	return (
        <div className="w-full h-[30rem] flex flex-wrap rounded-lg shadow-sm lg:h-auto">
            <div className="w-full h-[60%] bg-top bg-cover bg-no-repeat rounded-t-lg lg:h-[16rem] sm:h-[20rem]" style={{backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .15), rgba(0,0,0, .15)), url(${props.avatar})`}}></div>
            <div className="w-full bg-white rounded-b-lg p-8 2md:p-5">
                <h1 className="text-black text-xl font-black uppercase tracking-wide">{props.name}</h1>
                <h3 className="text-black text-base uppercase tracking-wide">{props.title}</h3>

                <div className="pt-5 flex flex-col">
                    <a className="text-black pb-2 uppercase text-xs" href={`tel:${props.phone}`} target="_blank">
                        <span className="font-medium tracking-wider">Telefon: </span>
                        <i className="font-semibold underline underline-offset-4 not-italic tracking-wider">{props.phone}</i>
                    </a>
                    <a className="text-black pb-4 uppercase text-xs" href={`mailto:${props.email}`} target="_blank">
                        <span className="font-medium tracking-wider">E-post: </span> 
                        <i className=" font-semibold underline underline-offset-4 not-italic tracking-wider">{props.email}</i>
                    </a>
                    <a href={props.cords} target="_blank" className="text-black uppercase text-xs font-semibold not-italic tracking-wider">{props.adress} <br></br> {props.city}</a>
                </div>
            </div>
        </div>
	);
};

export default Item;