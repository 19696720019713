import Karlstad from "./../../../assets/images/fastigheter/fast-one.png";
import Stockholm from "./../../../assets/images/fastigheter/fast-one.png";

export interface Location {
	pos: [number, number];
	text: string;
	owner: string;
	address?: string;
	municipality: string;
	img?: string;
}

export default [
	{
		pos: [59.3321097434723, 18.079503469927747],
		text: "Kontor Stockholm",
		address: "Strandvägen 7A",
		municipality: "114 51",
		owner: "Hofbro Fastigheter AB",
		img: Stockholm,
	},
	{
		pos: [59.370934412213415, 13.42597322759987],
		text: "Kontor Karlstad",
		address: "Frykmans Väg 29B",
		municipality: "653 46",
		owner: "Hofbro Fastigheter AB",
		img: Karlstad,
	},
] as Location[];
