import { useState } from "react";
import { Location } from "./locations/property";
import Marker from "../../assets/icons/marker_property.png";

interface MarkerProps extends Location {
	lat: number;
	lng: number;
	$hover?: boolean;
}

const PropertyMarker = (props: MarkerProps) => {
	const [clicked, setClicked] = useState(false);

	const handleClick = () => {
		setClicked(!clicked);
	};

	return (
		<div className="absolute w-24 h-6 -top-8 -left-12 flex align-center justify-center" onClick={handleClick}>
			{props.$hover && !clicked && (
				<p className="absolute bottom-8 bg-white text-black p-2 text-center rounded-sm z-50">{props.name}</p>
			)}

			{clicked && (
				<div className="absolute w-48 bottom-8 bg-white text-black p-2 rounded-sm flex flex-col gap-2 z-50">
					<div>
						<h2 className="font-bold text-sm capitalize">{props.name.toLowerCase()}</h2>
						<p>
							<span className="font-semibold">Adress: </span>
							{`${props.address}${props.zip ? `, ${props.zip}` : ""}`}
						</p>
						<p>
							<span className="font-semibold">Objekt: </span>
							{props.type}
						</p>
						<p>
							<span className="font-semibold">Omfattning: </span>
							{props.amount}
						</p>
						{props.builder && (
							<p>
								<span className="font-semibold">Byggherre: </span>
								{props.builder}
							</p>
						)}
						{props.start && (
							<p>
								<span className="font-semibold">Byggstart: </span>
								{props.start}
							</p>
						)}
						<p>
							<span className="font-semibold">Färdigställt: </span>
							{props.finished}
						</p>
						<p>
							<span className="font-semibold">Arkitekt: </span>
							{props.architect}
						</p>
						{props.contractor && (
							<p>
								<span className="font-semibold">Entreprenör: </span>
								{props.contractor}
							</p>
						)}
					</div>

					{props.img && <img className="w-full rounded-sm" src={props.img} alt="" />}

					<div className="my-1 w-full">
						<a
							href={`https://www.google.com/maps/place/${props.address}${
								props.zip ? `, ${props.zip}` : ""
							}`}
							target="_blank"
							className="w-full bg-black/5 text-black/75 px-2 py-1 rounded-sm hover:bg-black/10 transition"
						>
							Läs mer...
						</a>
					</div>
				</div>
			)}

			<img src={Marker} className="w-6 h-6" />
		</div>
	);
};

export default PropertyMarker;
