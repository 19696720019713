import React from "react";
import {Helmet} from "react-helmet";
import { Banner, Info, Carousel, InfoImage, Maps } from "./components/index";
import Image from "./../../assets/images/fastigheter/myren/myren.jpg";
import Image1 from "./../../assets/images/fastigheter/myren/myren1.jpg";
import Image2 from "./../../assets/images/fastigheter/myren/myren2.jpg";
import Image3 from "./../../assets/images/fastigheter/myren/myren3.jpg";
import Image4 from "./../../assets/images/fastigheter/myren/myren4.jpg";
import Image5 from "./../../assets/images/fastigheter/myren/myren5.jpg";
import Image6 from "./../../assets/images/fastigheter/myren/myren6.jpg";


const FastighetOne = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Myren, Strömstad</title>
                <link rel="canonical" href="https://hofbro.se/" />
            </Helmet>
            <Banner 
                image={Image} 
                location="Pågående" 
                title="MYREN, Strömstad" 
                text="Hofbro Fastigheter utvecklar 256 hyreslägenheter tillsammans med SPG ett stenkast från
Strömstad centrum. Projektet har erhållit statligt investeringsstöd." 
                Objekt="Hyresrätter"
                Omfattning="256 Lägenheter"
                Byggherre="SPG"
                Arkitekt="Tengbom"
                Entreprenor="Betonmast Göteborg"
                ></Banner>

<Carousel image1={Image1}  image2={Image2} image3={Image3} image4={Image4} image5={Image5} image6={Image6} />

        </main>
	);
};

export default FastighetOne;
