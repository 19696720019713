import React from "react";
import {Helmet} from "react-helmet";
import { Banner, Info, Carousel, InfoImage, Maps } from "./components/index";
import Image from "./../../assets/images/fastigheter/norraberget/norraberget.jpg";
import Image1 from "./../../assets/images/fastigheter/norraberget/norraberget1.jpg";
import Image2 from "./../../assets/images/fastigheter/norraberget/norraberget2.jpg";
import Image3 from "./../../assets/images/fastigheter/norraberget/norraberget3.jpg";
import Image4 from "./../../assets/images/fastigheter/norraberget/norraberget4.jpg";
import Image5 from "./../../assets/images/fastigheter/norraberget/norraberget5.jpg";
import Image6 from "./../../assets/images/fastigheter/norraberget/norraberget6.jpg";


const Norraberget = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Norra Berget, Sundsvall</title>
                <link rel="canonical" href="https://hofbro.se/" />
            </Helmet>
            <Banner 
                image={Image} 
                location="Avslutade" 
                title="Norra Berget, Sundsvall" 
                text="Hofbro Fastigheter utvecklar ett projekt med 67 st hyreslägenheter i norra bergets sluttning i
                Sundsvall. Lilium är sluttagare av projektet." 
                Objekt="Hyresrätter"
                Omfattning="67 Lägenheter"
                Byggherre="Hofbro Fastigheter"
                Arkitekt="Tengbom"
                Entreprenor="Betonmast i mälardalen"
                ></Banner>

<Carousel image1={Image1}  image2={Image2} image3={Image3} image4={Image4} image5={Image5} image6={Image6} />


        </main>
	);
};

export default Norraberget;
