interface ProjectProps {
	icon: string;
	title: string;
    text: string;
}

const Item = (props: ProjectProps) => {
	return (
        <div className="flex flex-col justify-center items-center bg-[#151c45] rounded-lg pb-5 pt-2">
            <div className="pb-5">
                <img src={props.icon} className="w-24"></img>
            </div>
            <h1 className="text-white text-3xl font-bold uppercase tracking-wide pb-2">{props.title}</h1>
            <p className="text-white text-base tracking-wide text-center w-[80%]">{props.text}</p>
        </div>
	);  
};

export default Item;
