import { Link } from "react-router-dom";
import "./../../index.scss"

interface ItemProps {
	link: string;
    text: string;
}

const Button = (props: ItemProps) => {
	return (
    <Link to={props.link} id="button" className="learn-more mb-10">
        <span className="circle" aria-hidden="true">
            <span className="icon arrow"></span>
        </span>
        <span className="button-text tracking-wider font-bold">{props.text}</span>
    </Link>
	);
};

export default Button;