import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Header, Footer, MenuOverlay } from "./components/index"
import { Hem, Pagaende, Fardiga, Kontakt, Myren, Norraberget, Karlstadgolfklubb, Oppentanum, Sannakajen, Fullero, Kyrkasen, Barken  } from "./pages/index"

function App() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <div className="App">
      <Header navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
      <MenuOverlay navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
      <Routes>
        <Route path="/" element={<Hem />}></Route>
        <Route path="/pagaende-projekt" element={<Pagaende />}></Route>
        <Route path="/fardiga-projekt" element={<Fardiga />}></Route>
        <Route path="/kontakta-oss" element={<Kontakt />}></Route>
        <Route path="/projekt/myren" element={<Myren />}></Route>
        <Route path="/projekt/norraberget" element={<Norraberget />}></Route>
        <Route path="/projekt/karlstadsgolfklubb" element={<Karlstadgolfklubb />}></Route>
        <Route path="/projekt/oppen-tanum" element={<Oppentanum />}></Route>
        <Route path="/projekt/Sannakajen" element={<Sannakajen />}></Route>
        <Route path="/projekt/Fullero" element={<Fullero />}></Route>
        <Route path="/projekt/kyrkasen" element={<Kyrkasen />}></Route>
        <Route path="/projekt/barken" element={<Barken />}></Route>



      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
