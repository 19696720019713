import React from "react";
import { Link } from "react-router-dom";
import { Fardiga, Button } from "../../../../reusable/index"
import FastFour from "./../../../../assets/images/fastigheter/fast-four.jpeg";
import FastFive from "./../../../../assets/images/fastigheter/fast-five.jpeg";
import FastSix from "./../../../../assets/images/fastigheter/fast-six.jpeg";
import FastSeven from "./../../../../assets/images/fastigheter/fast-seven.jpeg";
import FastEight from "./../../../../assets/images/fastigheter/fast-eight.jpeg";
import FastNine from "./../../../../assets/images/fastigheter/fast-nine.jpeg";
const Fade = require("react-reveal/Fade")

const Referenser = () => {
	return (
		<div className="w-full flex justify-center pt-[5%] pb-[5%]">
            <div className="w-[90%]">
				<h1 className="text-black text-4xl font-bold uppercase tracking-wider pb-20 text-center">Färdiga projekt</h1>
				<div className="w-full grid grid-cols-3 grid-rows-2 gap-10 xl:grid-cols-2 md:grid-cols-1">
					<Fardiga image={FastFour} location="BÅLSTA" type="HYRESRÄTTER" title="HYRESRÄTTER BÅLSTA" infoOne="456 m²" infoTwo="260 lägenheter"></Fardiga>
					<Fardiga image={FastFive} location="UPPSALA" type="HYRESRÄTTER" title="HYRESRÄTTER FULLERÖ" infoOne="456 m²" infoTwo="137 lägenheter"></Fardiga>
					<Fardiga image={FastSix} location="TYRESÖ" type="HYRESRÄTTER" title="LÄNGORNA" infoOne="456 m²" infoTwo="145 lägenheter"></Fardiga>

					<Fardiga image={FastSeven} location="KARLSTAD" type="BOSTÄDER" title="BRF BRYGGUDDEN" infoOne="456 m²" infoTwo="62 lägenheter"></Fardiga>
					<Fardiga image={FastEight} location="KARLSTAD" type="BOSTADSRÄTTER" title="BRF NICKEBO, RÅTORP" infoOne="456 m²" infoTwo="26 lägenheter"></Fardiga>
					<Fardiga image={FastNine} location="ÖREBRO" type="HYRESRÄTTER" title="KV GASUGNEN" infoOne="1500 m²" infoTwo="147 lägenheter"></Fardiga>
				</div>
				<div className="flex justify-center pt-16">
					<Button link="/fardiga-projekt" text="visa alla"></Button>
				</div>
			</div>
		</div>
	);
};

export default Referenser;