import React, { useState, useEffect } from 'react';
import logo from "./../../assets/logo_gray.svg";
import LinkedIn from "./../../assets/svg/icon/linkedin.svg"
import { Link } from 'react-router-dom';
import "./../header/style.css";

const Footer = () => {
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

	useEffect(() => {
		const intervalId = setInterval(() => {
		  setCurrentYear(new Date().getFullYear());
		}, 1000 * 60); // Update the year every minute, you can adjust the interval as needed
	
		return () => clearInterval(intervalId); // Cleanup the interval on component unmount
	  }, []);

	  
	return (
		<footer className="w-full flex flex-col justify-center items-center bg-[#1D1D1D] 2md:pt-[10%]">
			<div className="flex justify-between w-[70%] 2md:justify-center">
				<div className="pt-[5%] pb-[5%]">
					<h1 className="text-white text-2xl uppercase font-extrabold tracking-wider pb-3">Hofbro Fastigheter</h1>
					<div className="flex gap-10">
						<div className="">
							<h1 className="text-white text-lg font-bold tracking-wide">Karlstad</h1>
							<a href="https://www.google.com/maps/place/Frykmans+v%C3%A4g+29B,+653+46+Karlstad/@59.3709098,13.4237738,17z/data=!3m1!4b1!4m5!3m4!1s0x465cb2f86c61d4c7:0x900778e54297e284!8m2!3d59.3709071!4d13.4259624" target="_blank" className="text-white text-base font-normal">Frykmans Väg 29B<br></br>653 46 Karlstad</a>
						</div>
						<div className="">
							<h1 className="text-white text-lg font-bold tracking-wide">Stockholm</h1>
							<a href="https://www.google.com/maps/place/Strandv%C3%A4gen+7A,+114+51+Stockholm/@59.3320851,18.0773255,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d5a0d434e67:0x8af9d7279947f845!8m2!3d59.3320824!4d18.0795142" target="_blank" className="text-white text-base font-normal">Strandvägen 7A<br></br>114 51 Stockholm</a>
						</div>
					</div>
				</div>

				<div className="pt-[5%] pb-[5%] 2md:hidden">
					<h1 className="tet-white pb-2 uppercase font-bold tracking-wider">Navigera</h1>
					<div className="gap-1 flex flex-col">
						<Link className="" to="/"><span className="text-white text-sm uppercase tracking-wider underline-offset-2 font-sembibold hover:underline duration-800">Hem</span></Link>
						<Link className="" to="/kontakta-oss"><span className="text-white text-sm uppercase tracking-wider underline-offset-2 font-sembibold hover:underline duration-800">Om oss</span></Link>
						<Link className="" to="/pagaende-projekt"><span className="text-white text-sm uppercase tracking-wider underline-offset-2 font-sembibold hover:underline duration-800">Pågående projekt</span></Link>
						<Link className="" to="/fardiga-projekt"><span className="text-white text-sm uppercase tracking-wider underline-offset-2 font-sembibold hover:underline duration-800">Färdiga projekt</span></Link>
						<Link className="" to="/kontakta-oss"><span className="text-white text-sm uppercase tracking-wider underline-offset-2 font-sembibold hover:underline duration-800">Kontakta oss</span></Link>
					</div>
				</div>
			</div>
			<div className="w-[70%] flex justify-between pb-5 2md:justify-center">
				<a className="text-xs font-medium opacity-70">© {currentYear} Hofbro Fastigheter, alla rättigheter förbehållna.</a>
				<a className="text-xs font-medium opacity-70 2md:hidden">Made by Pejd Group.</a>
			</div>
		</footer>
	);
};

export default Footer;
