interface BannerProps {
	image: string;
    location: string;
	title: string;
	text: string;
	Objekt?: string;
	Omfattning?: string;
	Byggherre?: string;
	Arkitekt?: string;
	Entreprenor?: string;
}

const Banner = (props: BannerProps) => {
	return (
		<div className="w-full h-[100vh] flex justify-start items-end p-12 bg-cover bg-center bg-no-repeat" style={{backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .65), rgba(0,0,0, .65)), url(${props.image})`}}>
			<div className="flex flex-col gap-3">
                <div className="bg-[#111736] pl-6 pr-6 pt-2 pb-2 rounded-lg w-[16rem] min-w-[16rem]">
                    <h1 className="text-white text-sm uppercase font-bold tracking-wider text-center">{props.location}</h1>
                </div>
				<h1 className="text-6xl uppercase font-black tracking-widest">{props.title}</h1>
				<p className="text-xl w-[60%] opacity-80">{props.text}</p>
				<p className="text-[#fffff] text-base"><strong>Objekt:</strong> {props.Objekt}</p>
				<p className="text-[#fffff] text-base"><strong>Omfattning:</strong> {props.Omfattning}</p>
				<p className="text-[#fffff] text-base"><strong>Byggherre:</strong> {props.Byggherre}</p>
				<p className="text-[#fffff] text-base"><strong>Arkitekt:</strong> {props.Arkitekt}</p>
				<p className="text-[#fffff] text-base"><strong>Entreprenör:</strong> {props.Entreprenor}</p>
			</div>
		</div>
	);
};

export default Banner;