interface CarouselProps {
	image1: string;
	image2: string;
	image3: string;
	image4: string;
	image5: string;
	image6: string;
  }
  

  const Carousel = (props: CarouselProps) => {
	const { image1, image2, image3, image4, image5, image6 } = props;
	
	const people = [
		{
		  imageUrl: image1,
		},
		{
		  imageUrl: image2,
		},
		{
			imageUrl: image3,
		  },
		  {
			imageUrl: image4,
		  },
		  {
			imageUrl: image5,
		  },
		  {
			imageUrl: image6,
		  },
		// More people...
	  ];
	
	  return (
		<div className="bg-white ">
		  <div className="mx-auto max-w-1xl px-6 lg:px-8">
			<ul
			  role="list"
			  className="mx-auto mt- grid grid-cols-2 sm:grid-cols-1 py-16 gap-x-8 gap-y-8"
			>
			  {people.map((person, index) => (
				<img
				  key={index}
				  className="aspect-w-2 aspect-h-2 w-full object-cover"
				  src={person.imageUrl}
				  alt=""
				/>
			  ))}
			</ul>
		  </div>
		</div>
	  );
	};
	
  
  
  export default Carousel;
  