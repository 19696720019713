import React from "react";
import Video from "./../../../../assets/videos/hero.mp4";
const Fade = require("react-reveal/Fade")

const Hero = () => {
	return (
		<div className="w-full h-screen relative overflow-hidden flex justify-start items-end pl bg-black">
            <div className="absolute ml-[2%] mr-[2%] mb-[4%] z-20">
                <div className="w-[55rem] lg:w-full md:pb-[90px]">
					<h1 className="text-7xl font-black tracking-wider mb-5 uppercase lg:text-6xl md:text-5xl md:mb-2">Hofbro<br></br>Fastigheter</h1>
					<p className="text-2xl font-medium w-[70%] mb-10 tracking-wider lg:w-full lg:text-xl md:text-lg sm:w-[98%] md:mb-0">Fastighetsutveckling i samverkan och förtroende</p>
				</div>
            </div>
			<video autoPlay={true} controls={false} muted loop className="object-cover object-center h-full w-full opacity-50 rounded-none z-10">
				<source type="video/mp4;charset=UTF-8" src={Video} />
			</video>
		</div>
	);
};

export default Hero;