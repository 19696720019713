import React from "react";
import { Fardiga } from "../../../../reusable";
import FastFour from "./../../../../assets/images/fastigheter/fast-four.jpeg";
import FastFive from "./../../../../assets/images/fastigheter/fast-five.jpeg";
import FastSix from "./../../../../assets/images/fastigheter/fast-six.jpeg";
import FastSeven from "./../../../../assets/images/fastigheter/fast-seven.jpeg";
import FastEight from "./../../../../assets/images/fastigheter/fast-eight.jpeg";
import FastNine from "./../../../../assets/images/fastigheter/fast-nine.jpeg";
import FastEleven from "./../../../../assets/images/fastigheter/fast-eleven.png";
import FastTwelve from "./../../../../assets/images/fastigheter/fast-twelve.jpg";
import FastThirteen from "./../../../../assets/images/fastigheter/fast-thirteen.png";
import FastFourteen from "./../../../../assets/images/fastigheter/fast-fourteen.jpeg";
import FastFifteen from "./../../../../assets/images/fastigheter/fast-fifteen.png";
import FastSixteen from "./../../../../assets/images/fastigheter/fast-sixteen.jpg";
import FastSeventeen from "./../../../../assets/images/fastigheter/fast-seventeen.jpg";
import FastEighteen from "./../../../../assets/images/fastigheter/fast-eighteen.jpeg";
import FastNineteen from "./../../../../assets/images/fastigheter/fast-nineteen.jpeg";
import FastTwenty from "./../../../../assets/images/fastigheter/fast-twenty.jpeg";
import FastTwentyone from "./../../../../assets/images/fastigheter/fast-twentyone.jpg";
import FastTwentytwo from "./../../../../assets/images/fastigheter/fast-twentytwo.jpeg";
import FastTwentythree from "./../../../../assets/images/fastigheter/fast-twentythree.jpeg";
import FastTwentyfour from "./../../../../assets/images/fastigheter/fast-twentyfour.jpeg";
import FastTwentyfive from "./../../../../assets/images/fastigheter/fast-twentyfive.jpeg";
import FastTwentysix from "./../../../../assets/images/fastigheter/fast-twentysix.jpg";
import FastTwentyseven from "./../../../../assets/images/fastigheter/fast-twentyseven.jpeg";
import img37 from "./../../../../assets/images/fastigheter/img-37.jpeg";
import img38 from "./../../../../assets/images/fastigheter/img-38.jpg"
import Maps from "../map/map";

const Body = () => {
	return (
		<div className="w-full flex flex-col items-center pb-[5%] pt-[5%]">
			<div className="w-[90%]">
				<div className="w-full grid grid-cols-3 grid-rows-2 gap-10 xl:grid-cols-2 md:grid-cols-1">
					<Fardiga fardiga
						image={FastFour}
						location="BÅLSTA"
						type="HYRESRÄTTER"
						title="BÅLSTA"
						infoOne="? m²"
						infoTwo="260 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastFive}
						location="UPPSALA"
						type="HYRESRÄTTER"
						title="FULLERÖ"
						infoOne="? m²"
						infoTwo="137 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastSix}
						location="TYRESÖ"
						type="HYRESRÄTTER"
						title="LÄNGORNA"
						infoOne="? m²"
						infoTwo="145 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={img38}
						location="KARLSTAD"
						type="BOSTADSRÄTTER"
						title="NICKEBO"
						infoOne="? m²"
						infoTwo="26 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastNine}
						location="ÖREBRO"
						type="HYRESRÄTTER"
						title="GASUGNEN"
						infoOne="1500 m²"
						infoTwo="147 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastEleven}
						location="UPPLANDS-BRO"
						type="BOSTADSRÄTTER"
						title="BRUNNA PARK"
						infoOne="? m²"
						infoTwo="138 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastTwelve}
						location="UPPSALA"
						type="FÖRSKOLA"
						title="FÖRSKOLA FULLERÖ"
						infoOne="? m²"
						infoTwo="8 avdelningar"
					></Fardiga>
					<Fardiga fardiga
						image={FastThirteen}
						location="UPPSALA"
						type="BOSTADSRÄTTER"
						title="TALLBACKEN"
						infoOne="? m²"
						infoTwo="52 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastFourteen}
						location="UPPLANDS-BRO"
						type="HYRESRÄTTER"
						title="KUNGSÄNGEN"
						infoOne="? m²"
						infoTwo="139 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastFifteen}
						location="STRÖMSTAD"
						type="BOSTADSRÄTTER"
						title="KAVELDUNET"
						infoOne="? m²"
						infoTwo="18 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastSeventeen}
						location="KARLSTAD"
						type="LOKALER"
						title="KARLSTAD CCC"
						infoOne="24 000 m²"
						infoTwo=""
					></Fardiga>
					<Fardiga fardiga
						image={FastEighteen}
						location="KARLSTAD"
						type="HYRES/BOSTADSRÄTTER"
						title="HAMNSTADEN"
						infoOne="? m²"
						infoTwo="264 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={img38}
						location="KARLSTAD"
						type="BOSTADSRÄTTER"
						title="NICKEBO"
						infoOne="? m²"
						infoTwo="26 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastNineteen}
						location="UPPLANDS-BRO"
						type="BOSTADSRÄTTER"
						title="HANDELSPARKEN"
						infoOne="? m²"
						infoTwo="65 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastTwenty}
						location="KARLSTAD"
						type="GALLERIA, KONTOR"
						title="Valvet"
						infoOne="10 000 m²"
						infoTwo=""
					></Fardiga>
					<Fardiga fardiga
						image={FastTwentyone}
						location="TYRESÖ"
						type="BOSTADSRÄTTER"
						title="LÄNGORNA"
						infoOne="? m²"
						infoTwo="145 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastTwentytwo}
						location="SUNDSVALL"
						type="BOSTADSRÄTTER"
						title="HAGA STRAND"
						infoOne="? m²"
						infoTwo="48 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastTwentythree}
						location="KARLSTAD"
						type="HYRESRÄTTER"
						title="GALEASEN BRYGGUDDEN"
						infoOne="? m²"
						infoTwo="167 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastTwentyfour}
						location="KARLSTAD"
						type="BOSTADSRÄTTER"
						title="NICKEBO"
						infoOne="? m²"
						infoTwo="26 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastTwentyfive}
						location="KARLSTAD"
						type="BOSTADSRÄTTER"
						title="KANALSTRANDEN"
						infoOne="? m²"
						infoTwo="43 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastTwentysix}
						location="BÅLSTA"
						type="BOSTADSRÄTTER"
						title="STATIONSLUNDEN"
						infoOne="? m²"
						infoTwo="100 lägenheter"
					></Fardiga>
					<Fardiga fardiga
						image={FastTwentyseven}
						location="TANUM"
						type="KÖPCENTER"
						title="KÖPCENTER, TANUM"
						infoOne="40 000 m²"
						infoTwo=""
					></Fardiga>
					<Fardiga fardiga
						image={img37}
						location="SUNDSVALL"
						type="BOSTADSRÄTTSFÖRENING"
						title="NORRA KAJEN"
						infoOne="? m²"
						infoTwo="48 lägenheter"
					></Fardiga>
				</div>
			</div>
			<div className="w-[90%]">
				<Maps />
			</div>
		</div>
	);
};

export default Body;
