import React from "react";
import {Helmet} from "react-helmet";
import { Banner, Info, Carousel, InfoImage, Maps } from "./components/index";
import Image from "./../../assets/images/fastigheter/karlstadgolf/karlstad.golfklubb.jpg";
import Image1 from "./../../assets/images/fastigheter/karlstadgolf/karlstad.golfklubb1.jpg";
import Image2 from "./../../assets/images/fastigheter/karlstadgolf/karlstad.golfklubb2.jpg";
import Image3 from "./../../assets/images/fastigheter/karlstadgolf/karlstad.golfklubb3.jpg";




const Karlstadgolfklubb = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Karlstads golfklubb, Karlstad</title>
                <link rel="canonical" href="https://hofbro.se/" />
            </Helmet>
            <Banner 
                image={Image} 
                location="Pågående" 
                title="Karlstads golfklubb, Karlstad" 
                text="I direkt anslutning till Karlstad GK utvecklar Hofbro Fastigheter tillsammans med Karlstad GK ett
                bostadsområde med 18 villor. Projektet ligger naturskönt med västersol och utsikt mot golfbanan." 
                Objekt="Bostadsrättsförening Järnsjuan"
                Omfattning="67 Lägenheter"
                Byggherre="Hofbro Fastigheter"
                Arkitekt="Huspartner"
                Entreprenor="Huspartner"
                ></Banner>

            <Carousel image1={Image1}  image2={Image2} image3={Image3} image4="" image5="" image6="" />
        </main>
	);
};

export default Karlstadgolfklubb;
