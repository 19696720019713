import React from "react";
import { Banner } from "./../../reusable/index";
import { Employee, Map } from "./components/index";
import { Helmet } from "react-helmet";
import Image from "./../../assets/images/pexels-hassan-ouajbir-1535244.jpg"

const Kontakt = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Kontakt</title>
                <link rel="canonical" href="https://hofbro.se/kontakta-oss" />
            </Helmet>
            <Banner image={Image} title="Kontakta oss" text="Tveka inte att höra av er om ni har frågor eller funderingar."></Banner>
            <Employee></Employee>
            <Map></Map>
        </main>
	);
};

export default Kontakt;
