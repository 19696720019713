import React from "react";
import { Helmet } from "react-helmet";
import {Banner} from "./../../reusable/index";
import { Body } from "./components/index"
import BG from "./../../assets/images/contemporary-design-living-houses-modern-luxury-apartments-buildings-.jpg"

const Pagaende = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hofbro - Pågående projekt</title>
                <link rel="canonical" href="https://hofbro.se/pagaende-projekt" />
            </Helmet>
            <Banner image={BG} title="Pågående projekt" text="" />
            <Body />
		</main>
	);
};

export default Pagaende;
