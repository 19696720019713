import { useState } from "react";
import { Location } from "./locations/offices";
import Marker from "../../assets/icons/marker_gy.png";

interface MarkerProps extends Location {
	lat: number;
	lng: number;
	$hover?: boolean;
}

const OfficeMarker = (props: MarkerProps) => {
	const [clicked, setClicked] = useState(false);

	const handleClick = () => {
		setClicked(!clicked);
	};

	return (
		<div className="absolute w-24 h-6 -top-8 -left-12 flex align-center justify-center" onClick={handleClick}>
			{props.$hover && !clicked && (
				<p className="absolute bottom-8 bg-white text-black p-2 text-center rounded-sm capitalize z-50">
					{props.text.toLowerCase()}
				</p>
			)}

			{clicked && (
				<div className="absolute w-48 bottom-8 bg-white text-black p-2 rounded-sm flex flex-col gap-2 z-50">
					<div>
						<h2 className="font-bold text-sm capitalize">{props.text.toLowerCase()}</h2>
						<p className="capitalize">
							<span className="font-semibold">Adress: </span>
							{props.address ? `${props.address}, ${props.municipality.toLowerCase()}` : "Adress saknas."}
						</p>
						<p>
							<span className="font-semibold">Ägare: </span>
							{props.owner}
						</p>
					</div>

					{props.img && <img className="w-full rounded-sm" src={props.img} alt="" />}

					<div className="my-1 w-full">
						<a
							href={`https://www.google.com/maps/place/${props.address} ${props.municipality}`}
							target="_blank"
							className="w-full bg-black/5 text-black/75 px-2 py-1 rounded-sm hover:bg-black/10 transition"
						>
							Läs mer...
						</a>
					</div>
				</div>
			)}

			<img src={Marker} className="w-6 h-6" />
		</div>
	);
};

export default OfficeMarker;
