import React from "react";
import Item from "./item";

import Hakan from "./../../../../assets/images/employee/hakan_sv.jpg";
import Oscar from "./../../../../assets/images/employee/oscar_sv.jpg";

const Fade = require("react-reveal/Fade")

const Employee = () => {
	return (
        <div className="w-full flex justify-center items-center pt-[5%] pb-[5%] 2md:pt-[10%] 2md:pb-[10%]">
            <div className="w-[85%] flex justify-between gap-10 2xl:w-[90%] 2lg:flex-col 2lg:justify-center 2lg:items-center">
                <div className="w-[45%] flex flex-col justify-center gap-10 2xl:w-[45%] 2lg:w-[40rem] md:w-[95%]">
                    <div className="hidden">
                        <h1 className="text-[#111736] text-4xl font-bold uppercase tracking-wider pb-5">Om oss</h1>
                        <p className="text-[#111736] text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus impedit tenetur recusandae dolorum quod aspernatur! Non ex quam impedit assumenda illo, sunt id cupiditate quae, obcaecati iste voluptatum minus! Repellendus.<br></br><br></br>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias reiciendis tempora recusandae temporibus optio unde iste. Possimus reprehenderit nam non ex, eveniet cumque tenetur id aperiam, blanditiis tempore voluptatum? Laborum?</p>
                    </div>

                    <div className="">
                        <div className="flex justify-between gap-10 2xl:flex-col">
                            <div className="">
                                <h3 className="text-[#111736] text-xl font-bold tracking-wider">Erfarenhet</h3>
                                <p className="text-[#111736] text-base">Efter många år av erfarenhet och många genomförda projekt av olika projekttyper så har vi lärt oss att nyckeln till framgång i ett projekt är att samla erfarenhet i ett tidigt skede och dra nytta av alla aktörers olika kunskaper.</p>
                            </div>
                            <div className="">
                                <h3 className="text-[#111736] text-xl font-bold tracking-wider">Kvalitét</h3>
                                <p className="text-[#111736] text-base">Detta skapar effektiva projekt samtidigt som man får med sig ekonomin och kvalité som  genomsyras ända fram till färdigställande och in till förvaltningsskedet.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center  md:w-[95%]">
                    <div className="w-[43rem] grid grid-cols-2 gap-8 2lg:w-[40rem] 2lg:gap-16 md:w-full sm:grid-cols-1 sm:grid-rows-2">
                        <Item avatar={Hakan} name="Håkan Broström" title="Partner" phone="+46 70-592 33 81" email="hakan@hofbro.se" adress="Frykmans Väg 29B" city="653 46 Karlstad" cords="https://www.google.com/maps/place/Frykmans+v%C3%A4g+29B,+653+46+Karlstad/@59.3709071,13.4237738,17z/data=!3m1!4b1!4m5!3m4!1s0x465cb2f86c61d4c7:0x900778e54297e284!8m2!3d59.3709071!4d13.4259625"></Item>
                        <Item avatar={Oscar} name="Oscar Broström" title="Partner" phone="+46 70-518 71 06" email="oscar@hofbro.se" adress="Strandvägen 7A" city="114 51 Stockholm" cords="https://www.google.com/maps/place/Strandv%C3%A4gen+7A,+114+56+Stockholm/@59.3320824,18.0773255,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d44d23b1bc5:0x1cecfedd2b980708!8m2!3d59.3320824!4d18.0795142"></Item>
                    </div>
                </div>
            </div>
        </div>
	);
};

export default Employee;